<template>
  <div>
    <v-tabs
      v-if="tab !== null || availablePaymentMethods.length === 1"
      v-model="tab"
      background-color="transparent"
      color="primary"
      grow
      show-arrows
    >
      <v-tab
        v-for="(paymentType, index) in availablePaymentMethods"
        :key="index"
        :tab-value="paymentType"
      >
        <v-icon small left>{{ paymentTypes[paymentType].icon }}</v-icon>
        {{ paymentTypes[paymentType].name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :value="null" class="pa-6 pb-4">
        <SelectPaymentMethod
          :availablePaymentMethods="availablePaymentMethods"
          :payment="payment"
          @select="tab = $event"
          @close="$emit('close')"
        />
      </v-tab-item>
      <v-tab-item
        v-for="paymentType in availablePaymentMethods"
        :key="paymentType"
        :value="paymentType"
      >
        <component
          :is="paymentTypes[paymentType].component"
          :integrationData="integrationData"
          :bankRestriction="bankRestriction"
          :paymentId="paymentId"
          :payment="payment"
          :tickets="tickets"
          :party="party"
          @lock="lock"
          @update="$emit('update')"
          @refresh="$emit('refresh')"
          @close="$emit('close')"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SelectPaymentMethod from "./modules/SelectPaymentMethod.vue";
import GeneralPix from "./modules/GeneralPix.vue";
import PagarmeCreditCard from "./modules/PagarmeCreditCard.vue";

export default {
  components: { GeneralPix, PagarmeCreditCard, SelectPaymentMethod },
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    tab: null,
    selectedPaymentMethod: null,
    locked: false,

    paymentTypes: {
      PIX: {
        id: "PIX",
        icon: "mdi-qrcode",
        name: "PIX",
        component: "GeneralPix",
        description: "Pague com o seu aplicativo de banco",
      },
      CREDIT_CARD: {
        id: "CREDIT_CARD",
        icon: "mdi-credit-card",
        name: "Cartão de Crédito",
        component: "PagarmeCreditCard",
        description: "Pague com seu cartão de crédito",
      },
    },
  }),
  methods: {
    lock(paymentType) {
      this.locked = paymentType;
    },
  },
  computed: {
    availablePaymentMethods() {
      if (this.bankRestriction) return ["CREDIT_CARD"];

      if (!this.locked && this.payment.paymentType === "UNKNOWN")
        return Object.keys(this.paymentTypes).filter(
          (paymentType) =>
            !this.payment.disallowOnlinePaymentMethods.includes(paymentType)
        );

      return [this.locked || this.payment.paymentType];
    },
  },
  props: {
    paymentId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    bankRestriction: {
      type: Object,
    },
  },
};
</script>
