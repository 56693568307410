<template>
  <v-dialog v-model="dialog" width="550" persistent overlay-opacity="0.75">
    <v-card rounded="lg">
      <v-card-title v-show="step === 1" class="text-18">
        <span style="word-break: normal; overflow-wrap: break-word">
          Verificação de Segurança
        </span>
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="step" elevation="0" class="transparent">
          <v-stepper-content step="1" class="pa-0">
            <p>
              Para finalizar o pagamento, precisamos confirmar algumas
              informações.
            </p>

            <v-form @submit.prevent v-model="valid">
              <h6 class="my-4">Confirme o Endereço de Faturamento</h6>

              <div v-if="!address" class="text-center">
                <PlaceSearch
                  placeholder="Busque o endereço de faturamento"
                  label="Endereço de faturamento"
                  :value="address"
                  @input:raw="formatAddress"
                  outlined
                  :delay="1000"
                  hint="Endereço cadastrado no cartão"
                  :hide-details="false"
                  :search-types="['address']"
                  autofocus
                />

                <v-btn text x-small @click="address = {}">
                  Preencher endereço manualmente
                </v-btn>
              </div>
              <template v-else>
                <v-text-field
                  v-model="address.street"
                  label="Endereço"
                  hint="Endereço com número"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                />
                <v-text-field
                  v-model="address.street2"
                  label="Complemento"
                  :disabled="loading"
                  hint="Apartamento, bloco, etc"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="address.city"
                  label="Cidade"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                />
                <div class="d-flex gap-4">
                  <v-text-field
                    v-model="address.state"
                    class="flex-1"
                    label="Estado"
                    hint="Sigla do estado (SP, RJ, PR, etc)"
                    :disabled="loading"
                    outlined
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v && v.length === 2) ||
                        'Sigla do estado com 2 caracteres',
                    ]"
                    dense
                  />
                  <v-text-field
                    v-model="address.country"
                    label="País"
                    class="flex-1"
                    hint="Sigla do país (BR, US, etc)"
                    :disabled="loading"
                    outlined
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v && v.length === 2) ||
                        'Sigla do país com 2 caracteres',
                    ]"
                    dense
                  />
                </div>
                <v-text-field
                  v-model="address.zipCode"
                  label="CEP"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="tel"
                />
              </template>
            </v-form>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div>
              <v-scroll-y-transition leave-absolute hide-on-leave>
                <div
                  :key="verificationStep"
                  class="d-flex flex-column gap-2 text-center justify-center px-2 pt-4"
                  style="height: 270px"
                >
                  <v-icon size="60" left>
                    {{ verificationSteps[verificationStep].icon }}
                  </v-icon>
                  <h5 class="mb-0">
                    {{ verificationSteps[verificationStep].title }}
                  </h5>
                  <p
                    v-if="verificationSteps[verificationStep].description"
                    style="text-wrap: balance"
                  >
                    {{ verificationSteps[verificationStep].description }}
                  </p>
                </div>
              </v-scroll-y-transition>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>

      <v-card-actions v-if="step === 1">
        <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="verify"
          :disabled="!valid || !address || loading"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
import TICKET from "@/services/app/ticket";
import PlaceSearch from "@/views/global/PlaceSearch.vue";
import { mapGetters } from "vuex";
import { captureException } from "@sentry/vue";

export default {
  name: "Pagarme3DS",
  components: {
    PlaceSearch,
  },
  data: () => ({
    dialog: false,
    step: 1,
    loading: false,
    error: null,
    valid: false,
    address: null,
    verificationStep: "IDLE",
    verificationSteps: {
      IDLE: {
        icon: "mdi-shield-check",
        title: "Verificação Necessária",
        description:
          "Para garantir a segurança do seu pagamento, precisamos que você realize algumas verificações de segurança.",
      },
      PAYMENT_DATA: {
        icon: "mdi-text-box",
        title: "Coletando Dados do Pagamento",
        description:
          "Reunindo os detalhes do seu pedido e informações de pagamento.",
      },
      BANK_CONNECTION: {
        icon: "mdi-lock",
        title: "Estabelecendo Conexão Segura",
        description:
          "Criando uma conexão criptografada com sua instituição financeira para proteger seus dados.",
      },
      PAYMENT_PROCESS: {
        icon: "mdi-credit-card",
        title: "Processando pagamento",
        description: "Finalizando o pagamento, aguarde um instante.",
      },
    },
    invisibleForm: false,
    scriptTag: null,
  }),
  methods: {
    open() {
      this.dialog = true;
      this.address = null;
      this.verificationStep = "IDLE";
      this.step = 1;
      this.loading = false;
      this.error = null;
      this.destroyForm();
    },
    formatAddress(data) {
      const street = [
        this.findAddressComponent(data.address_components, "route")?.long_name,
      ];
      const number = this.findAddressComponent(
        data.address_components,
        "street_number"
      )?.long_name;
      if (number) street.push(number);

      this.address = {
        name: data.name,
        street: street.join(", "),
        street2: this.findAddressComponent(
          data.address_components,
          "subpremise"
        )?.long_name,
        neighborhood: this.findAddressComponent(
          data.address_components,
          "sublocality"
        )?.long_name,
        city: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_2"
        )?.long_name,
        state: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_1"
        )?.short_name,
        country: this.findAddressComponent(data.address_components, "country")
          ?.short_name,
        zipCode: this.findAddressComponent(
          data.address_components,
          "postal_code"
        )?.long_name,
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
    },
    findAddressComponent(data, componentName) {
      return data.find((item) => item.types.includes(componentName));
    },
    async pay(cardToken) {
      try {
        if (this.verificationStep === "PAYMENT_PROCESS") return;
        this.error = null;
        this.loading = true;
        this.verificationStep = "PAYMENT_PROCESS";
        const expiration = moment(this.card.expiration, "MM/YY");

        const { success, data } = await TICKET.pay(this.paymentId, {
          type: "CREDIT_CARD",
          address: this.address,
          card: {
            token: cardToken,
            number: this.card.number.replace(/\D/g, ""),
            expiration: expiration.format("MM/YYYY"),
            securityCode: this.card.security_code,
            holder: {
              name: this.card.holder.name,
              tax_id: this.card.holder.tax_id.replace(/\D/g, ""),
            },

            installments: this.card.installments,
            bin: this.card.number.substring(0, 6),
            last4: this.card.number.substring(this.card.number.length - 4),
          },
        });

        if (!success) throw new Error(data.message);

        this.close();
        this.$emit("success", data.status);
      } catch (error) {
        this.$emit("update");
        this.error =
          error.message || "Erro ao processar pagamento, tente novamente";
        this.loading = false;
        this.step = 1;
      }
    },
    async verify() {
      try {
        this.error = null;
        this.loading = true;
        this.step = 2;

        // Get Payment Data
        this.verificationStep = "PAYMENT_DATA";
        await this.sleep(700);

        // Tokenize Card
        this.verificationStep = "BANK_CONNECTION";
        const expiration = moment(this.card.expiration, "MM/YY");
        const token = await this.tokenizeCard({
          number: this.card.number,
          holder_name: this.card.holder.name,
          holder_document: this.card.holder.tax_id.replace(/\D/g, ""),
          exp_month: expiration.format("MM"),
          exp_year: expiration.format("YYYY"),
          cvv: this.card.security_code,
        });

        // Get Bank Connection
        await this.sleep(200);
        await this.pay(token);
      } catch (error) {
        captureException(error, {
          tags: { module: "3ds" },
          extra: {
            paymentId: this.paymentId,
            cardBin: this.card?.number?.replace(/\D/g, "").substring(0, 6),
          },
        });

        this.error = error.message;
        this.loading = false;
        this.step = 1;
      }
    },
    async tokenizeCard(cardData) {
      const body = {
        type: "card",
        card: cardData,
      };

      const token = this.integrationData.publicKey;

      const response = await axios.post(
        `https://api.pagar.me/core/v5/tokens?appId=${token}`,
        body
      );

      const data = response.data;
      return data.id;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  watch: {
    "card.expiration": {
      handler: function (value) {
        if (!value) return;

        if (value.length === 1 && value > 1)
          this.card.expiration = `0${value}/`;

        if (value.length === 2 && value > 12) this.card.expiration = "12/";
      },
      immediate: true,
    },
    "address.state": {
      handler: function (value) {
        if (!value) return;
        this.address.state = value.toUpperCase();
      },
      immediate: true,
    },
    "address.country": {
      handler: function (value) {
        if (!value) return;
        this.address.country = value.toUpperCase();
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on("3ds-verification", this.open);
  },
  beforeDestroy() {
    this.$emit("close");
    this.$root.$off("3ds-verification", this.open);
  },

  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    paymentId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
